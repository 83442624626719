<template>
  <div>
    <Header />
    <div class="content-wrapper">
      <main class="main-wrapper clearfix">
        <div class="container">

          <div class="row page-title clearfix">
            <div class="page-title-left">
              <h5 class="page-title-heading mt-1 py-2">Users</h5>
            </div>
          </div>

          <div class="widget-list">

            <div class="widget-holder" v-for="u in users" :key="u.id">
              <div class="widget-bg">
                <div class="widget-body">
                  <div class="row">
                    <div class="col pt-1">
                      <div class="clearfix">
                        <div class="float-left d-none d-sm-inline-block">
                          <img :src="u.avatar" class="rounded-circle avatar avatar-sm mr-3" alt="">
                        </div>
                        <div class="float-left">
                          <strong>{{ u.name }}</strong> @{{ u.username }} <span v-if="self.id && u.id === self.id" class="badge badge-success"><span class="full">You</span></span> <span v-if="u.two_factor" class="badge badge-primary"><span class="full">2FA</span></span><br>
                          {{ u.email }}
                        </div>
                      </div>
                    </div>
                    <div class="col-1 col-sm-3 text-right pt-2">
                      <div class="d-none d-sm-inline-block">
                        <i v-if="u.enable_billing" class="fal fa-credit-card text-muted fa-lg pt-1 ml-3"></i>
                        <span v-if="u.site_admin" class="badge border ml-3"><span class="full">{{ $config.name }}</span></span>
                            <i class="fal fa-trash-alt text-danger pt-1 ml-3" @click="deleteUser(u.id)" title="Delete User"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
import Header from '@/components/AccountHeader.vue';

export default {
  data() {
    return {
      users: [],
      self: {},
    };
  },
  async mounted() {
    try {
      if (!this.$auth.profile.site_admin) {
        this.$store.commit('error', 404);
        return;
      }
      document.title = `Dashboard - Admin - ${this.$config.pretty_name}`;
      this.users = (await this.$cache.api.get('/users')).data;
      for (let i = 0; i < this.users.length; i += 1) {
        if (this.users[i].id === this.$auth.profile.id) {
          this.self = this.users[i];
          break;
        }
      }
    } catch (e) {
      this.$store.commit('error', e);
    }
  },
  methods: {
    async deleteUser(userId) {
      if (confirm('Are you sure you want to delete this user?')) {
        try {
          const response = await this.$api.delete(`/users/${userId}`);
          if (response.status === 200) {
            this.users = this.users.filter(user => user.id !== userId);
            alert('User deleted successfully');
          } else {
            alert(`Failed to delete the user: ${response.data.message}`);
          }
        } catch (error) {
          console.error('Error deleting user:', error);
          alert(`Error deleting user: ${error.response.data.message}`);
        }
      }
    },
  },
  components: {
    Header,
  },
};
</script>
